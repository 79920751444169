import { HelmetDatoCms } from '@rasahq/gatsby-source-datocms';
import { graphql } from 'gatsby';
import React from 'react';
import Player from 'react-player/lazy';
import styled from 'styled-components';

import { Button } from 'src/components/atoms/Button';
import { Card } from 'src/components/atoms/Card';
import { HighlightedText } from 'src/components/atoms/HighlightedText';
import { Icon } from 'src/components/atoms/Icon';
import { List, ListItem } from 'src/components/atoms/List';
import { Column, Grid } from 'src/components/molecules/Grid';
import { ShowcaseGrid } from 'src/components/molecules/ShowcaseGrid';
import { Link } from 'src/containers/Link';
import { ShowcaseCategories } from 'src/containers/ShowcaseCategories';
import { FixedImg } from 'src/embeds/image/image-dato';
import Layout from '../layouts/default';
export const query = graphql`
  query ShowcaseDetailData($slug: String) {
    showcase: datoCmsShowcase(slug: { eq: $slug }) {
      heading
      slug
      category {
        slug
        title
      }
      channels {
        title
      }
      community
      linkedAuthor {
        ... on DatoCmsCustomer {
          id
          name
          logo {
            ...GatsbyDatoCmsImageCustom
          }
          bylineNode {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on DatoCmsPerson {
          id
          name
          bioNode {
            childMarkdownRemark {
              html
            }
          }
          image {
            ...GatsbyDatoCmsImageCustom
          }
        }
      }
      preHeading
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      resources {
        url
        text
        a11yText
      }
      languages
      demoUrl
      industry {
        title
      }
      video {
        url: video {
          thumbnail: thumbnailUrl(format: jpg)
          streaming: streamingUrl
        }
        height
        width
      }
      localLogo {
        ...GatsbyDatoCmsImageCustom
      }
      localAuthorNode {
        childMarkdownRemark {
          html
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;
const ButtonContainer = styled.div`
  padding: 1.5rem 1.5rem 0.5rem;
  text-align: center;
`;
const ResourceLink = styled(Link)`
  color: inherit;
  text-decoration: underline;
  font-size: 1rem;

  &:focus,
  &:hover {
    text-decoration: none;
  }
`;
const CompanyDescription = styled.div`
  margin-bottom: 1.75rem;

  > :last-child {
    margin-bottom: 0;
  }
`;
const VideoHold = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding-bottom: ${({ aspect }) => `${100 / aspect}%`};
  background-color: #f2f4fa;
`;

const ShowcaseVideo = ({
  data: {
    width,
    height,
    url: { streaming, thumbnail },
  },
}) => {
  const minAspect = 16 / 9;
  const aspect = width / height;
  return (
    <VideoHold aspect={Math.max(aspect, minAspect)}>
      <Player
        controls
        muted
        loop
        playing={true}
        width={aspect < minAspect ? `${(100 / minAspect) * aspect}%` : '100%'}
        height="100%"
        url={streaming}
        style={{
          position: 'absolute',
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />
    </VideoHold>
  );
};

const ShowcaseDetails = ({ data, pageContext }) => {
  const { showcase } = data;
  const languages = JSON.parse(showcase.languages ?? '[]');
  const channels = showcase.channels?.map(({ title }) => title);
  return (
    <Layout
      pageContext={{
        frontmatter: {
          title: 'Community Showcase',
          alternativeHeader: true,
        },
      }}
    >
      <HelmetDatoCms seo={showcase.seoMetaTags}>
        {pageContext.noIndex && <meta name="robots" content="noindex" />}
      </HelmetDatoCms>
      <ShowcaseGrid
        sidebar={
          <ShowcaseCategories activeCategory={showcase.category.slug} activeShowcase={showcase.slug} />
        }
      >
        <HighlightedText>{showcase.preHeading}</HighlightedText>
        <h1>{showcase.heading}</h1>

        <Card noPadding>
          {!!showcase.video && <ShowcaseVideo data={showcase.video} />}
          {!!showcase.demoUrl && (
            <ButtonContainer>
              <Button large to={showcase.demoUrl} target="_blank">
                Chat with this assistant!
              </Button>
            </ButtonContainer>
          )}
        </Card>
        <Grid spacious columns="3fr 2fr">
          <Column>
            <h3>Meet the Assistant</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: showcase.contentNode.childMarkdownRemark.html,
              }}
            />
            {showcase.resources?.length > 0 && (
              <>
                <h3>Additional Resources</h3>

                <List>
                  {showcase.resources.map((resource, index) => (
                    <ListItem
                      icon={
                        <Icon
                          transform={{
                            y: 1.5,
                          }}
                          size="lg"
                          icon="caret-right"
                        />
                      }
                      key={index}
                    >
                      <ResourceLink aria-label={resource.a11yText} to={resource.url}>
                        {resource.text}
                      </ResourceLink>
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </Column>
          <Column>
            <Card tertiary small>
              {!!languages.length && (
                <>
                  <HighlightedText>Languages Supported</HighlightedText>
                  <p>{languages.join(', ')}</p>
                </>
              )}

              {!!channels.length && (
                <>
                  <HighlightedText>Channels</HighlightedText>
                  <p>{channels.join(', ')}</p>
                </>
              )}

              {!!showcase.industry && (
                <>
                  <HighlightedText>Industry</HighlightedText>
                  <p>{showcase.industry.title}</p>
                </>
              )}

              {!!showcase.preHeading && (
                <>
                  <HighlightedText>Use Case</HighlightedText>
                  <p>{showcase.preHeading}</p>
                </>
              )}
            </Card>
            {(!!showcase.linkedAuthor || !!showcase.localAuthorNode?.childMarkdownRemark.html) && (
              <Card tertiary small>
                {!!showcase.linkedAuthor?.logo && (
                  <LogoRow>
                    {/* @ts-ignore */}
                    <FixedImg
                      constrain
                      width={Math.round(
                        (showcase.linkedAuthor.logo.width / showcase.linkedAuthor.logo.height) * 30,
                      )}
                      height={30}
                      data={showcase.linkedAuthor.logo}
                    />
                  </LogoRow>
                )}
                {!!showcase.linkedAuthor?.bylineNode && (
                  <CompanyDescription
                    dangerouslySetInnerHTML={{
                      __html: showcase.linkedAuthor.bylineNode.childMarkdownRemark.html,
                    }}
                  />
                )}
                {!!showcase.linkedAuthor?.bioNode && (
                  <CompanyDescription
                    dangerouslySetInnerHTML={{
                      __html: showcase.linkedAuthor.bioNode.childMarkdownRemark.html,
                    }}
                  />
                )}
                {!!showcase.localLogo && !!showcase.localLogo.length && (
                  <LogoRow>
                    {/* @ts-ignore */}
                    {showcase.localLogo.map((logo) => (
                      <FixedImg
                        constrain
                        width={Math.round((logo.width / logo.height) * 30)}
                        height={30}
                        data={logo}
                      />
                    ))}
                  </LogoRow>
                )}
                {!!showcase.localAuthorNode && (
                  <CompanyDescription
                    dangerouslySetInnerHTML={{
                      __html: showcase.localAuthorNode.childMarkdownRemark.html,
                    }}
                  />
                )}
              </Card>
            )}
          </Column>
        </Grid>
      </ShowcaseGrid>
    </Layout>
  );
};

const LogoRow = styled.div`
  margin-bottom: 1.5rem;
`;
export default ShowcaseDetails;
